.flexrow{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 8px;

}
.flexcol{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
   
}

.css-128fb87-MuiDataGrid-toolbarContainer {
  flex-wrap: nowrap !important;
}
.css-1yqais2{
  flex-wrap: nowrap !important;
}
.bannermain{
display: flex;

justify-content: center;
align-items: center;
background: rgba(161, 67, 100, 0.83);
box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
border-radius: 25px;


}
.flexcenter{
    display: flex;
   justify-content: center;
    align-items: center;
}
.userback{
  background: rgba(179, 174, 173, 0.25);
box-shadow: 1px 1px 8px 1px rgba(0, 0, 0, 0.25);
}

a:-webkit-any-link {
    color: inherit;
    cursor: pointer;
    text-decoration: none;
  }
  a:-webkit-any-link:hover {
    color: #EE548A; /* Change the color to your desired hover color */
  }

  .backimg {
    background-image: url('../src/assets/images/bottom.svg'),url('../src/assets/images/top.svg');
    background-position: top right, bottom left ;
    background-repeat: no-repeat, no-repeat;
    background-origin: content-box; /* Add this line */
  }

  .reset{
    background-image: url('../src/assets/images/reset.svg');
    background-repeat: no-repeat, no-repeat;
    background-position: center ;
  }
  .contactomg{
    background-image: url('../src/assets/images/contact.svg');
    background-repeat: no-repeat, no-repeat;
    background-position: center ;
  width: 100%;
  }
  .contactcard{
    background: rgba(179, 174, 173, 0.25);
box-shadow: 1px 1px 8px 1px rgba(0, 0, 0, 0.25);
border-radius: 50px;
width: 100%;
max-width: 738px;

  }
  .anotherbutton{
    font-family: 'Poppins';
font-style: normal;
font-weight: 400;
font-size: 24px;
line-height: 36px;
text-align: center;
cursor: pointer;
border-radius: 10px;
color: rgba(161, 67, 100, 0.83);
text-align: center;
padding: 12px;
border: none;
width: 218px;
height: 57px;
  }